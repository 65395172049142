import {Tooltip, Whisper} from "rsuite";
import React from "react";

export const Help = ({children}) => (
    <Whisper trigger="hover" speaker={<Tooltip>{children}</Tooltip>}>
        <span className="glyphicon glyphicon-question-sign"/>
    </Whisper>
);

export default Help;
