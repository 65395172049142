import React, {useContext, useEffect} from "react";
import {useDispatch} from "react-redux";
import {setField, useField} from "../store/fields-slice";
import Parameters from "./parameters";
import {SaveButton} from "./save-button";
import CalculationTable from "./table";
import Dates from "./dates";
import {useCalculationDetails} from "../store/calculation-details";
import {useDirty, useView} from "../store/state-slice";
import {ErrorBoundary} from "./error-boundary";
import updateDetails from "../store/update-details";
import CalculationContext from "../context";
import {Placeholder} from "rsuite";
import {useOrganizationQuery} from "../../../store/api";

export default function PanelBody() {
    const dispatch = useDispatch()
    const {i18n} = useContext(CalculationContext)
    const {isFetching, data, error, fulfilledTimeStamp, status, requestId} = useCalculationDetails()
    const {organization} = useContext(CalculationContext)
    const {isLoading} = useOrganizationQuery(organization)
    const dirty = useDirty()
    const note = useField("note")
    const view = useView()

    useEffect(
        () => {
            if ("fulfilled" === status) {
                dispatch(updateDetails(data))
            }
        },
        [status, requestId, fulfilledTimeStamp]
    )

    if (isLoading) {
        return (
            <div className="panel-body">
                <Placeholder.Grid active/>
            </div>
        )
    }

    if (error) {
        console.error(error)
        return (
            <div className="panel-body">
                <div className="alert alert-danger">
                    <p>
                        <span className="glyphicon glyphicon-warning-sign"/>
                        {" "}
                        <strong>{i18n.tr(error.data?.message ?? "Ein Fehler ist aufgetreten.")}</strong>
                    </p>
                    {(error.data?.errors?.errors ?? []).map(e => <p key={e}>{i18n.tr(e)}</p>)}
                </div>
            </div>
        )
    }

    return (
        <div className="panel-body">
            <div className="row">
                {dirty && (
                    <div className="col-xs-12">
                        <p className="alert alert-info">
                            <span className="glyphicon glyphicon-alert"></span>
                            {" "}
                            <strong>Achtung!</strong>
                            {" "}
                            Ungespeicherte Änderungen.
                        </p>
                    </div>
                )}
            </div>

            <div className="row">
                {"entries" === view && (
                    <>
                        <div className="col-xs-12">
                            <ErrorBoundary>
                                <Parameters/>
                            </ErrorBoundary>
                        </div>

                        <div className="col-xs-12">
                            <ErrorBoundary>
                                <CalculationTable/>
                            </ErrorBoundary>
                        </div>
                    </>
                )}

                {"dates" === view && (
                    <ErrorBoundary>
                        <Dates/>
                    </ErrorBoundary>
                )}
            </div>

            <div className="row" style={{marginTop: "3rem"}}>
                <div className="col-xs-12 col-md-8 col-lg-9">
                    <textarea
                        className="form-control" rows="10"
                        disabled={isFetching}
                        value={note}
                        onChange={onChange}
                    />
                </div>

                <div className="col-xs-12 col-md-4 col-lg-3 text-right bottom-save">
                    {dirty && (
                        <p className="alert alert-info">
                            <span className="glyphicon glyphicon-alert"></span>
                            {" "}
                            <strong>Achtung!</strong>
                            {" "}
                            Ungespeicherte Änderungen.
                        </p>
                    )}

                    <SaveButton/>
                </div>
            </div>
        </div>
    )

    function onChange({target: {value}}) {
        dispatch(setField("note", value))
    }
}
