import React, {useContext, useEffect} from "react"
import {useGetCalculationsQuery} from "../../../store/api";
import CalculationContext from "../context";
import {useDispatch} from "react-redux";
import {useField} from "../store/fields-slice";
import {Icon, Loader} from "rsuite";
import {DialogAction} from "./action";
import {loadCalculation} from "../store/load-calculation";

export function Calculations() {
    const id = useField("id")
    const {itinerary, organization, hasMarginTax, urlUtils} = useContext(CalculationContext)
    const dispatch = useDispatch()
    const {data: calculations = [], isLoading, refetch} = useGetCalculationsQuery(itinerary, {
        pollingInterval: 3 * 60 * 1000, // 3min
        refetchOnMountOrArgChange: true,
        skip: false
    })

    const showCurrencies = 1 < new Set(calculations.map(({currency}) => currency)).size
    const active = calculations.filter(({active}) => active)
    const inactive = calculations.filter(({active}) => !active)

    useEffect(
        () => {
            if (!isLoading) {
                let calculation = new URLSearchParams(document.location.search.substring(1)).get('calculation')
                if (calculation) {
                    calculation = calculations.find(({id}) => id === calculation)
                }
                if (calculation) {
                    selectCalculation(calculation)
                    return
                }
                if (active.length) {
                    selectCalculation(active[0])
                } else if (inactive.length) {
                    selectCalculation(inactive[0])
                }
            }
        },
        [isLoading]
    )

    const context = {
        moduleId: "tourism-journey-calculation",
        viewId: "calculation-create",
        contextObjectRef: {id: itinerary, modelId: "tourism/itinerary"}
    };

    return (
        <nav className="navbar navbar-default">
            {isLoading ? (
                <div className="navbar-text">
                    <Loader/>
                </div>
            ) : (
                <>
                    <CalculationsSelect
                        title="Aktiv"
                        calculations={active}
                        id={id}
                        showCurrencies={showCurrencies}
                        selectCalculation={selectCalculation}
                    />
                    <CalculationsSelect
                        title="Inaktiv"
                        calculations={inactive}
                        id={id}
                        showCurrencies={showCurrencies}
                        selectCalculation={selectCalculation}
                    />
                </>
            )}

            <div className="navbar-right navbar-text">
                <DialogAction
                    icon={<Icon icon=" sio-icon-plus"/>}
                    appearance="primary"
                    color="green"
                    context={context}
                    onResult={onResult}
                />
            </div>
        </nav>
    )

    function onResult(calculation) {
        refetch() // @fixme should not be necessary here
        selectCalculation(calculation)
    }

    function selectCalculation(calculation) {
        urlUtils.setParams("calculation", calculation.id)
        dispatch(loadCalculation(calculation, organization, hasMarginTax))
    }
}

const CalculationsSelect = (
    {
        id,
        calculations,
        title,
        selectCalculation,
        showCurrencies
    }
) => !calculations.length ? null : (
    <>
        <div className="navbar-header">
            <span className="navbar-brand">{title}:</span>
        </div>

        <ul className="nav navbar-nav">
            {calculations.map(calculation => {
                const [title, subtitle] = calculation.label.split("\n")
                return (
                    <li key={calculation.id} className={id === calculation.id ? "active" : ""}>
                        <a onClick={() => selectCalculation(calculation)}>
                            {showCurrencies && <small className="currency">{calculation.currency}</small>}
                            {title}
                            {subtitle && <small><br/>{subtitle}</small>}
                        </a>
                    </li>
                )
            })}
        </ul>
    </>
)

