import React, {useContext} from "react";
import CalculationContext from "../context";
import {useChoice, useChoiceIds} from "../store/choices-slice";
import {useDisplayColumns, useHeader, useHideOccupancyChoices} from "../store/state-slice";
import {useField} from "../store/fields-slice";
import cs from "classnames"
import {Button, Tooltip, Whisper} from "rsuite";
import Help from "./help";

export default function TableHeader({toggleCollapsed}) {
    const {dateConverter, i18n} = useContext(CalculationContext)
    const displayColumns = useDisplayColumns()
    const [first, second] = useHeader()
    const choiceIds = useChoiceIds()
    const hideOccupancyChoices = useHideOccupancyChoices()
    const choices = choiceIds.length - 1
    const marginParticipants = useField("marginParticipants")

    return (
        <thead>
            <tr>
                <th colSpan={2 + displayColumns.length} className="border-right">
                    <Whisper trigger="hover" speaker={<Tooltip>kompakte Ansicht</Tooltip>}>
                        <Button size="xs" onClick={() => toggleCollapsed()}>
                            <span className="glyphicon glyphicon-resize-small"></span>
                        </Button>
                    </Whisper>
                </th>

                {!hideOccupancyChoices && choiceIds.map((choiceId, idx) => (
                    <ChoiceLabel key={choiceId} id={choiceId} last={choices === idx}/>
                ))}

                {first.map(([from, to, colSpan]) => (
                    <th key={from + to} colSpan={colSpan} className="text-center border-right">
                        {dateConverter.range(from, to)}
                    </th>
                ))}
            </tr>

            <tr>
                <th colSpan="2">Leistung</th>

                {displayColumns.map((field, idx) => (
                    <th key={field}
                        className={cs("text-right", {"border-right": idx === displayColumns.length - 1})}>
                        {i18n.tr("tourism-journey-calculation." + field)}
                        { i18n.tr("tourism-journey-calculation."+field+"Help") !== "tourism-journey-calculation."+field+"Help" ?
                            <>&nbsp;<Help>{i18n.tr("tourism-journey-calculation."+field+"Help")}</Help></> : null }
                    </th>
                ))}

                {second.map(([from, to, border, groupId]) => (
                    <th
                        key={groupId + from + to}
                        className={cs("text-right", "pax", {
                            "border-right": border,
                            "margin-pax": from <= marginParticipants && marginParticipants <= to
                        })}
                    >
                        {from}
                        {to !== from && ("–" + to)}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

function ChoiceLabel({id, last}) {
    const {shortLabel, childPrices} = useChoice(id)
    const labels = [shortLabel].concat(
        ...childPrices.map(cp => cp[1])
    )

    return labels.map((label, idx) => (
        <th key={label} rowSpan="2" className={cs("occupancy-small", {"border-right": last && idx + 1 === labels.length})}>
            <span className={cs("short-label", {child: 0 < idx})} title={label}>{label}</span>
        </th>
    ))
}
