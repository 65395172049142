import React, {useContext, useMemo} from "react";
import CalculationContext from "../context";
import {FlexboxGrid, Icon, Nav} from "rsuite";
import {Container} from "aurelia-framework";
import {DuplicateActionHandler} from "../../../action/handlers/duplicate.action-handler";
import {WorkflowActionHandler} from "../../../action/handlers/workflow.action-handler";
import {DeleteActionHandler} from "../../../action/handlers/delete.action-handler";
import {useGetCalculationsQuery, useOrganizationQuery} from "../../../store/api";
import {useDispatch, useSelector} from "react-redux";
import {selectEntryTotal} from "../store/entries-slice";
import {clearFields, setField, useField} from "../store/fields-slice";
import Action from "./action";
import {SaveButton} from "./save-button";
import {useCalculationDetails} from "../store/calculation-details";
import {makeClean, setView, useDirty, useView} from "../store/state-slice";
import {loadCalculation} from "../store/load-calculation";

const deleteActionHandler = Container.instance.get(DeleteActionHandler)
const duplicateActionHandler = Container.instance.get(DuplicateActionHandler)
const workflowActionHandler = Container.instance.get(WorkflowActionHandler)

export default function PanelHeader() {
    const dispatch = useDispatch()
    const {i18n, itinerary, title, organization, viewActionHandler} = useContext(CalculationContext);
    const entries = useSelector(state => selectEntryTotal(state))
    const active = useField("active")
    const currency = useField("currency")
    const id = useField("id")
    const dirty = useDirty()
    const view = useView()
    const {isFetching} = useCalculationDetails()
    const {refetch} = useGetCalculationsQuery(itinerary)
    const {data} = useOrganizationQuery(organization)
    const settings = data?.settings?.tourismJourneyCalculationSettings
    const {displayServiceWorkflow, displayDates, displayDateOverride} = settings ?? {}

    const activateContext = useMemo(
        () => {
            const action = active ? "deactivate" : "activate"
            return {
                id,
                workflowId: "tourism-journey-calculation/" + action,
                confirm: i18n.tr(
                    "tourism-journey-calculation." + action + "-confirm",
                    {sprintf: {title}}
                )
            }
        }, [active]
    )

    const serviceWorkflowContext = {
        id,
        workflowId: "tourism-journey-calculation/generate-days-and-services",
    }

    const dateOverrideContext = {
        moduleId: "tourism-journey-calculation",
        viewId: "date-override",
        modal: true,
        id
    }

    const occupancyChoiceEditContext = {
        moduleId: "tourism-journey-calculation",
        viewId: "occupancy-choices-edit",
        modal: true,
        id
    }

    return (
        <FlexboxGrid className="panel-heading" style={{textTransform: "none"}} justify="space-between">
            <FlexboxGrid.Item colspan={3}>
                <h4>
                    {i18n.tr("sio.field.currency") + ": " + currency}
                </h4>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item style={{flex: "1 1 auto"}}>
                <Nav appearance="tabs" activeKey={view} onSelect={handleNavSelect}>
                    <Nav.Item eventKey="entries" icon={<Icon icon=" sio-icon-list"/>}>
                        Leistungen
                    </Nav.Item>

                    {displayDates && (
                        <Nav.Item eventKey="dates" icon={<Icon icon=" sio-icon-calendar"/>}>
                            Termine
                        </Nav.Item>
                    )}
                </Nav>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item className="text-right">
                {displayServiceWorkflow && (
                    <Action appearance="ghost" disabled={isFetching || dirty || !entries}
                            context={serviceWorkflowContext}
                            handler={workflowActionHandler}>
                        Reiseverlauf
                    </Action>
                )}

                <Action appearance="ghost" disabled={isFetching || active || dirty}
                        handler={viewActionHandler}
                        context={occupancyChoiceEditContext}
                        onResult={afterOccupancyChoiceEdit}>
                    Belegungsarten
                </Action>

                {displayDateOverride && (
                    <Action appearance="ghost" disabled={isFetching || active || dirty}
                            handler={viewActionHandler}
                            context={dateOverrideContext}
                            onResult={afterDateOverride}>
                        Spaltensplits
                    </Action>
                )}

                <Action appearance="ghost" disabled={isFetching || dirty}
                        handler={duplicateActionHandler}
                        context={{id, formId: "tourism-journey-calculation/duplicate"}}
                        onResult={afterDuplicate}>
                    Duplizieren
                </Action>

                <Action color="yellow" disabled={dirty || isFetching || !entries}
                        context={activateContext}
                        handler={workflowActionHandler}
                        onResult={afterActivate}>
                    {active ? "Deaktivieren" : "Aktivieren"}
                </Action>

                <Action
                    color="red" disabled={isFetching}
                    icon={<Icon icon=" glyphicon sio-icon-trash"/>}
                    handler={deleteActionHandler}
                    config={{modelId: "tourism-journey-calculation/calculation"}}
                    context={{id}}
                    onResult={afterDelete}
                />

                <SaveButton header/>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )

    function handleNavSelect(activeKey) {
        dispatch(setView(activeKey))
    }

    function afterDelete() {
        dispatch(clearFields())
        refetch()
    }

    function afterDuplicate() {
        refetch()
    }

    function afterActivate({wasCancelled}) {
        if (!wasCancelled) {
            dispatch(setField("active", !active))
            dispatch(makeClean())
            refetch()
        }
    }

    function afterDateOverride({dateOverride}) {
        dispatch(setField("dateOverride", dateOverride))
        dispatch(makeClean())
        refetch()
    }

    function afterOccupancyChoiceEdit(calculation) {
        dispatch(loadCalculation(calculation, settings))
    }
}
